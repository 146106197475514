import React from 'react';
import FileUpload from 'components/data/FileUpload';
import InlineAlert from 'components/ui-components/InlineAlert';
import File from 'components/data/Files';
import Translation from 'components/data/Translation';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import SelectorGrid from '../../grid';

import '../../../../styles/asset-finder.scss';

interface Props {
    onRemove: (item: SourceData) => void;
    onChangeAcceptedList?: (acceptedList: File[]) => void;
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
    onAdd: (item: SourceData) => void;
    list?: SourceData[];
    acceptedTypes?: string;
    maxFileSize?: number;
    multiple?: boolean;
}

/**
 * Upload images using the file upload feature
 */
const SelectorUpload: React.FC<Props> = ({ onSelect, list = [], acceptedTypes = 'all', onRemove, onAdd, maxFileSize, multiple, onChangeAcceptedList }) => {
    /**
     * Handle file upload
     * Adding the data after uploading
     * @param {*} listOfSourceData
     */
    const handleFileUpload = async (listOfSourceData: SourceData[]) => {
        const fileType = listOfSourceData[0].fileType;
        const fileUrl = ['video', 'image'].includes(fileType) ? listOfSourceData[0].url : listOfSourceData[0].stillUrl;

        let item;
        switch (fileType) {
            case 'image':
                item = await File.loadImage(fileUrl);
                break;
            case 'video':
                item = await File.loadVideo(fileUrl);
                break;
        }

        const width = item && item.width ? item.width : null;
        const height = item && item.height ? item.height : null;

        const sourceData = { ...listOfSourceData[0] };

        const itemToAdd = {
            ...sourceData,
            width,
            height,
            humanSize: File.humanReadableSize(listOfSourceData[0].size),
            key: listOfSourceData[0].url,
            subtitle:
                width && height
                    ? `${width} x ${height} | ${File.humanReadableSize(listOfSourceData[0].size)}`
                    : `${File.humanReadableSize(listOfSourceData[0].size)}`
        };

        onAdd(itemToAdd);
    };

    let height = window.innerHeight - 156;
    if (maxFileSize) height = window.innerHeight - 210;

    // Don't show images from Aprimo
    list = list.filter((item) => !item?.aprimo);

    return (
        <div className="asset-finder">
            <div className="asset-finder__upload">
                {maxFileSize && (
                    <InlineAlert type="info">
                        {Translation.get('assetGallerySelector.selectorUpload.maxFileSize', 'content-space', {
                            maxFileSize: maxFileSize
                        })}
                    </InlineAlert>
                )}
                <FileUpload
                    fileType={acceptedTypes}
                    onUploadComplete={handleFileUpload}
                    onChangeAcceptedList={onChangeAcceptedList}
                    height={list && list.length > 0 ? undefined : height}
                    maxFileSize={maxFileSize}
                    multiple={multiple}
                />
            </div>
            <div className="asset-finder__list">
                {list && list.length > 0 && <SelectorGrid isLoading={false} isRefresh={false} onRemove={onRemove} list={list} onSelect={onSelect} />}
            </div>
        </div>
    );
};

export default SelectorUpload;
