import React, { useMemo, useState } from 'react';
import { TemplateType } from 'types/templates.type';
import { PreviewListItemType } from 'components/ui-components/PreviewListItem';
import ViewState from 'components/data/ViewState';
import SelectorViewSearchBar from './components/selector-view-search-bar';
import SelectorViewType from './interfaces/SelectorViewType';
import SelectorViewContent from './components/selector-view-content';

import './styles/main.scss';

const DEFAULT_VIEW_TYPE: SelectorViewType = 'grid';
interface Classes {
    root?: string;
    content?: string;
    searchBar?: string;
    listView?: string;
    gridView?: string;
}

interface SelectorViewItem {
    thumbnail?: string;
    templateType?: TemplateType;
}

export type SelectorViewItemType<T> = PreviewListItemType & SelectorViewItem & T;

interface Props<SelectorViewItemType> {
    items: SelectorViewItemType[];
    classes?: Classes;
    onSelect: (item: SelectorViewItemType) => void;
    onSearch: (searchQuery: string) => void;
}

/**
 * The component is responsible for rendering the selector view.
 */
const SelectorView = <T extends object>({ items, classes, onSelect, onSearch }: Props<SelectorViewItemType<T>>) => {
    const getDefaultViewValue = () => {
        const viewStateValue = ViewState.get('selectorView', 'assetGalleryDialogAssetsView');

        if (!viewStateValue) return DEFAULT_VIEW_TYPE;
        return viewStateValue;
    };

    const defaultViewType = useMemo(() => getDefaultViewValue(), []);
    const [viewType, setViewType] = useState<SelectorViewType>(defaultViewType);

    const handleViewTypeChange = (viewType: SelectorViewType) => {
        ViewState.set('selectorView', 'assetGalleryDialogAssetsView', viewType);
        setViewType(viewType);
    };

    return (
        <div className={`selector-view ${classes?.root}`}>
            <SelectorViewSearchBar className={classes?.searchBar} viewType={viewType} onSearch={onSearch} onViewChange={handleViewTypeChange} />
            <SelectorViewContent items={items} viewType={viewType} onSelect={onSelect} />
        </div>
    );
};

export default SelectorView;
