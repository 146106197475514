import { useRef } from 'react';
import AssetGalleryDialogState, { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import File from 'components/data/Files';
import ComponentStore from 'components/data/ComponentStore';

/**
 * The responsibility of this hook is to handle the upload selector logic.
 * @param onSelect Event handler for selecting an asset.
 * @param onMutation Event handler for mutating the Asset Gallery Dialog data/value.
 * @param onChangeAcceptedList Event handler for changing the accepted list.
 */
const useUploadSelector = (
    onSelect: (item: SourceData, skipCropper?: boolean, isMultipleUpload?: boolean) => void,
    onMutation: (data: unknown, type?: string) => void,
    onChangeAcceptedList?: (acceptedList: File[]) => void
) => {
    const assetGalleryDialog: AssetGalleryDialogState | undefined = ComponentStoreHelpers.get('AssetGalleryDialog');
    const defaultSourceData = assetGalleryDialog?.data.sourceData ? assetGalleryDialog.data.sourceData : []; // Get the source data from the asset gallery dialog state.
    const sourceDataListRef = useRef(defaultSourceData); // This is used to keep track on the item that already has been uploaded.
    const acceptedFileListRef = useRef<File[]>([]); // Keeps track of the accepted list to be uploaded, this is used to determine how many assets are going to go through the upload process.

    /**
     * Checks if the upload is multiple upload or not. If the acceptedFileList has only one item and the multiple upload prop is true, return false to open the asset editor directly.
     * @param {*} isMultipleUpload - The multiple upload prop.
     * @returns {boolean} - Returns true if the upload is multiple upload, otherwise returns false.
     */
    const checkIfUploadIsMultiple = (isMultipleUpload?: boolean) => {
        if (acceptedFileListRef.current.length === 1 && isMultipleUpload) {
            return false; // If the acceptedFileList has only one item and the multiple upload prop is true, return false to open the asset editor directly.
        }

        return isMultipleUpload;
    };

    /**
     * Updates the acceptedFileList and calls the onChangeAcceptedList callback function.
     * @param {*} acceptedList - The accepted list.
     */
    const handleOnChangeAcceptedList = (acceptedList: File[]) => {
        acceptedFileListRef.current = acceptedList; // Store the accepted list.

        if (!onChangeAcceptedList) {
            return;
        }

        onChangeAcceptedList(acceptedList); // If onChangeAcceptedList not undefined, call the onChangeAcceptedList callback function
    };

    /**
     * Add a new item to the source data
     */
    const handleSelectorUpload = (item: SourceData) => {
        const { config }: AssetGalleryDialogState = ComponentStoreHelpers.get('AssetGalleryDialog');

        const newSourceData = Array.isArray(sourceDataListRef.current) ? [...sourceDataListRef.current, item] : [];
        const isMultipleUpload = checkIfUploadIsMultiple(config.multiple); // Check if the upload is multiple upload or not.

        onMutation(newSourceData, 'sourceData'); // Save the new source data.

        onSelect(item, false, isMultipleUpload);
        sourceDataListRef.current = newSourceData; // Update the sourceDataList, to keep track on the uploaded items.
    };

    /**
     * Remove item from the list
     */
    const handleSelectorRemove = (item: SourceData, onMutation: (data: unknown, type?: string) => void) => {
        const { data }: AssetGalleryDialogState = ComponentStore.get('AssetGalleryDialog');
        const sourceData = data.sourceData;

        let filteredSourceData: SourceData[] = [];

        if (Array.isArray(sourceData)) {
            filteredSourceData = sourceData.filter((sourceDataItem) => sourceDataItem.url !== item.url);
        }

        sourceDataListRef.current = filteredSourceData; // Update the sourceDataList with the filtered source data, to keep track on the uploaded items after removing an item.

        onMutation(filteredSourceData, 'sourceData');
    };

    return { handleSelectorUpload, handleSelectorRemove, handleOnChangeAcceptedList };
};

export default useUploadSelector;
