import React from 'react';

interface Classes {
    root?: string;
    image?: string;
    description?: string;
}

interface Props {
    classes?: Classes;
    description: string;
    imageUrl: string;
}

/** Component for displaying an AI content example. */
const AiContentExample: React.FC<Props> = ({ classes, description, imageUrl }) => {
    return (
        <div className={classes?.root}>
            <img className={classes?.image} src={imageUrl}></img>
            <div className={classes?.description}>{description}</div>
        </div>
    );
};

export default AiContentExample;
