import axios, { HttpStatusCode } from 'axios';
import User from 'components/data/User';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import AiContentData from '../interfaces/ai-content-data';

/** Default image resolution. */
const DEFAULT_DIMENSION = '1024x1024';
/** The default number of images that should be generated. */
const DEFAULT_NUMBER_OF_IMAGES = 4;

/** Service for generating AI content.*/
class AiContentGeneratorService {
    /**
     * Generates images based on seed text, number of images and dimension.
     * @param seedText Prompt text for the AI to generate.
     * @param numberOfImages Number of images to generate.
     * @param dimension Image resolution.
     * @returns A list of generated images based on the seed text.
     */
    static async generateImages(seedText: string, numberOfImages = DEFAULT_NUMBER_OF_IMAGES, dimension = DEFAULT_DIMENSION) {
        try {
            const { data } = await axios.post<AiContentData>(
                process.env.APP_MEDIA_URL + 'media/imageGenerator',
                { dimension, numberOfImages, seedText },
                { headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` } }
            );

            return data;
        } catch (error: any) {
            if (error.response.status === HttpStatusCode.TooManyRequests) {
                SnackbarUtils.error(Translation.get('selectors.aiContent.promptLimitReached', 'asset-gallery-dialog'));
            } else {
                SnackbarUtils.error(Translation.get('selectors.aiContent.errorGeneratingImages', 'asset-gallery-dialog'));
            }
        }
    }
}

export default AiContentGeneratorService;
