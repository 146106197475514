import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import SelectorContentSpace from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/asset-library/components/selector-contentspace';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';

interface SelectorLibraryWrapperState {
    acceptedTypes?: string;
    contentSpaceCollection?: string | boolean;
    contentSpaceCollectionQuery?: string | boolean;
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorContentSpace component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onSelect Event handler for selecting an asset.
 */
const SelectorLibraryWrapper: React.FC<Props> = ({ onSelect }) => {
    const { acceptedTypes, contentSpaceCollection, contentSpaceCollectionQuery } = useComponentStore<SelectorLibraryWrapperState>('AssetGalleryDialog', {
        fields: {
            acceptedTypes: 'config.acceptedTypes',
            contentSpaceCollection: 'data.contentSpaceData.contentSpaceCollection',
            contentSpaceCollectionQuery: 'data.contentSpaceData.contentSpaceCollectionQuery'
        }
    });

    return (
        <SelectorContentSpace
            acceptedTypes={acceptedTypes}
            onSelect={onSelect}
            contentSpaceCollection={contentSpaceCollection}
            contentSpaceCollectionQuery={contentSpaceCollectionQuery}
        />
    );
};

export default SelectorLibraryWrapper;
