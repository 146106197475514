import Translation from 'components/data/Translation';
import ExampleBeach from '../images/example_beach.jpg';
import ExampleHill from '../images/example_hill.jpg';
import ExampleBridge from '../images/example_bridge.jpg';
import ExampleNewYork from '../images/example_new-york.jpg';
import AiContentExampleData from '../interfaces/ai-content-example';

/** List of ai content examples. */
const aiContentExamples: AiContentExampleData[] = [
    {
        imageUrl: ExampleBeach,
        description: Translation.get('assetGallerySelector.selectorAiContent.example1', 'content-space')
    },
    {
        imageUrl: ExampleHill,
        description: Translation.get('assetGallerySelector.selectorAiContent.example2', 'content-space')
    },
    {
        imageUrl: ExampleBridge,
        description: Translation.get('assetGallerySelector.selectorAiContent.example3', 'content-space')
    },
    {
        imageUrl: ExampleNewYork,
        description: Translation.get('assetGallerySelector.selectorAiContent.example4', 'content-space')
    }
];

export default aiContentExamples;
