import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetGalleryDialogState, { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import ComponentStore from 'components/data/ComponentStore';
import SelectorUrl from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/url/components/selector-url';

interface SelectorUrlWrapperState {
    store: AssetGalleryDialogState['store'];
    acceptedTypes: AssetGalleryDialogState['config']['acceptedTypes'];
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorUrlWrapper component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onSelect Event handler for selecting an asset.
 */
const SelectorUrlWrapper: React.FC<Props> = ({ onSelect }) => {
    const { acceptedTypes, store } = useComponentStore<SelectorUrlWrapperState>('AssetGalleryDialog', {
        fields: {
            acceptedTypes: 'config.acceptedTypes',
            store: 'store'
        }
    });

    /**
     * Updates the unsplash store state.
     * @param key Key of the store.
     * @param data Data to be updated.
     */
    const handleStateChange = (key, data) => {
        ComponentStore.setModel('AssetGalleryDialog', `store.${key}`, data);
    };

    return (
        <SelectorUrl acceptedTypes={acceptedTypes} onStateChange={(state) => handleStateChange('url', state)} currentState={store?.url} onSelect={onSelect} />
    );
};

export default SelectorUrlWrapper;
