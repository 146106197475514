import React from 'react';
import CustomizedDialogs from 'components/ui-components/Dialog/components/dialog';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Translation from 'components/data/Translation';

import './styles/main.scss';

interface AssetGalleryWarningDialogState {
    isDialogOpen: boolean;
}

interface Props {
    onClose: () => void;
    onConfirm: () => void;
}

/**
 * Responsible for rendering the warning dialog when the user tries to close the dialog while the crop operation is running.
 * This component was created to prevent re-rendering the whole AssetGalleryDialog component when the warning dialog state changes.
 */
const AssetGalleryWarningDialog: React.FC<Props> = ({ onClose, onConfirm }) => {
    const { isDialogOpen } = useComponentStore<AssetGalleryWarningDialogState>('AssetGalleryDialog', {
        fields: {
            isDialogOpen: 'components.warningDialog.isDialogOpen'
        }
    });

    return (
        <CustomizedDialogs
            open={isDialogOpen}
            onClose={onClose}
            showCancel={true}
            onConfirm={onConfirm}
            confirmText={Translation.get('input.common.yes', 'common')}
            title={Translation.get('assetGalleryDialog.assetEditor.cropOperationRunning', 'content-space')}>
            <div className="warning-dialog__title">{Translation.get('assetGalleryDialog.assetEditor.stoppingCropOperation', 'content-space')}</div>
        </CustomizedDialogs>
    );
};

export default AssetGalleryWarningDialog;
