import classNames from 'classnames';
import React, { useState } from 'react';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import FileUpload from 'components/data/FileUpload';
import { FileType } from 'components/bricks/types/brick.type';
import OverlayLoadingIndicatorWrapper from 'components/assets/AssetEditor/components/OverlayLoadingIndicator';
import Translation from 'components/data/Translation';
import ImageToVideoService from './services/image-to-video-service';
import ImageToVideoHelper, { ImageToVideoErrorType } from './helpers/image-to-video-helper';

import './styles/main.scss';

interface Classes {
    root?: string;
}

interface Props {
    classes?: Classes;
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

const DEFAULT_FILE_TYPE: FileType = 'image';
const DEFAULT_MULTIPLE = false;

/** Selector for converting an image to a video using AI. */
const SelectorImageToVideo: React.FC<Props> = ({ classes, onSelect }) => {
    const [loadingText, setLoadingText] = useState('');

    /** Handles the start of the uploading process. */
    const handleOnUploadStart = () => {
        setLoadingText(Translation.get('selectors.imageToVideo.uploadingImage', 'asset-gallery-dialog')); // Set loading text to uploading image.
    };

    /**
     * Handle the either upload or generate error.
     * @param errorType The type of error to be handled, could be either 'upload' or 'generate'.
     */
    const handleError = (errorType: ImageToVideoErrorType) => {
        setLoadingText(''); // Reset the loading text.

        ImageToVideoHelper.showErrorMessage(errorType); // Show the error message based on the error type.
    };

    /**
     * Handles the conversion of the image to a video.
     * @param imageUrl The image url to be converted to a video.
     * @returns The generated video url or null if something goes wrong.
     */
    const handleConvertImageToVideo = async (imageUrl: string) => {
        setLoadingText(Translation.get('selectors.imageToVideo.generatingVideo', 'asset-gallery-dialog')); // Set loading text to generating video.

        const generatedVideo = await ImageToVideoService.convertImageToVideo(imageUrl); // Generate video from the uploaded image.

        // If the video is not generated, return
        if (!generatedVideo) {
            return null;
        }

        return generatedVideo;
    };

    /**
     * Handle the upload complete event.
     * @param listOfSourceData List of source data that has been uploaded.
     */
    const handleOnUploadComplete = async (listOfSourceData: SourceData[]) => {
        let item = ImageToVideoHelper.getFirstItemFromUploadedList(listOfSourceData); // Get the first item from the uploaded list.

        // If the item is not found, show upload error and return.
        if (!item) {
            handleError('upload');
            return;
        }

        const generatedVideo = await handleConvertImageToVideo(item.url); // Generate video from the uploaded image.

        // If the video is not generated, show generated video error and return.
        if (!generatedVideo) {
            handleError('generate');
            return;
        }

        item = ImageToVideoHelper.updateImageItemToVideo(item, generatedVideo); // Update the item to a video item.

        onSelect(item); // Call the onSelect function with the updated item.
    };

    const fileUploadHeight = window.innerHeight - 156; // Set the file upload height, the number 156 is the same as the upload selector.

    return (
        <div className={classNames('image-to-video', classes?.root)}>
            <OverlayLoadingIndicatorWrapper
                text={loadingText}
                isLoading={loadingText !== ''}
                className="image-to-video__loading-overlay"
                classes={{ content: 'image-to-video__loading-overlay__content', title: 'image-to-video__loading-overlay__title' }}>
                <FileUpload
                    className="image-to-video__loading-overlay__file-upload"
                    fileType={DEFAULT_FILE_TYPE}
                    onUploadComplete={handleOnUploadComplete}
                    multiple={DEFAULT_MULTIPLE}
                    onUploadingStart={handleOnUploadStart}
                    height={fileUploadHeight}
                />
            </OverlayLoadingIndicatorWrapper>
        </div>
    );
};

export default SelectorImageToVideo;
