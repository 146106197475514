import React, { useState } from 'react';
import Translation from 'components/data/Translation';
import SearchField from 'components/ui-components/SearchField';

import './styles/selector-toolbar.scss';

interface Props {
    /** The initial query value. */
    initQuery?: string;
    /** Callback function that will be triggered when the search term changes. */
    onSearch: (query: string) => void;
    /** The placeholder for the search field. */
    placeholder?: string;
    /**The label of the input. It is only used for layout. The actual labelling is handled by InputLabel. */
    label?: string;
    /** If true, the search will trigger on enter. */
    searchOnEnter?: boolean;
    /** The minimum length of the search term. */
    minLength?: number;
}

/**
 * This is the toolbar for the selector
 */
const SelectorToolbar: React.FC<Props> = ({ initQuery = '', onSearch, placeholder, label, searchOnEnter, minLength }) => {
    const [query, setQuery] = useState(initQuery);
    const [tooShort, setTooShort] = useState(false);

    /** Resets the search state. */
    const resetSearchState = () => {
        setQuery(''); // Reset the local query state for the input field.
        onSearch(''); // Trigger the search event with an empty query.
    };

    /**
     * Handles the change event of the toolbar input.
     * @param value - The new value of the input.
     */
    const handleChange = (value: string, isSearchCanceled?: boolean) => {
        if (isSearchCanceled) {
            resetSearchState(); // If the cancel search event is triggered, reset the search state.
            return;
        }

        setQuery(value); // Update the local query state for the input field.

        if (!searchOnEnter) {
            onSearch(value); // If searchOnEnter is false, trigger the search event immediately.
        } else {
            if (minLength && value.length > minLength) {
                setTooShort(false); // If the search term is longer than the minimum length, reset the tooShort state.
            }
        }
    };

    /**
     * Handles the key press event for the search input.
     * @param e - The key press event.
     * @param minlength - The minimum length of the search query.
     */
    const handleKeyPress = (e, minlength?: number) => {
        const newValue = e.target.value;

        if (searchOnEnter && e.key === 'Enter') {
            if (minLength && newValue.length <= minLength) {
                setTooShort(true);
            }
            if (!minlength || (minLength && newValue.length > minLength)) {
                onSearch(newValue);
            }
        }
    };

    return (
        <div className="selector-toolbar">
            <SearchField
                searchTerm={query}
                searchPlaceholder={placeholder}
                label={label}
                classes={{ root: 'selector-toolbar__search-root' }}
                onKeyDown={(e) => handleKeyPress(e, minLength)}
                onChange={(_, query, isSearchCanceled) => handleChange(query, isSearchCanceled)}
                formHelperText={minLength && tooShort ? Translation.get('assetGalleryDialog.assetSelectView.descriptionLengthAI', 'content-space') : ''}
                error={minLength ? tooShort : undefined}
            />
        </div>
    );
};

export default SelectorToolbar;
