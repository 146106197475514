import React, { useState } from 'react';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import Translation from 'components/data/Translation';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import SelectorToolBar from '../../toolbar';
import UnsplashHelper from '../helpers/unsplash-helper';
import UnSplashData from '../interfaces/UnsplashData';
import SelectorGrid from '../../grid';
import UnsplashService from '../services/unsplash-service';

import '../../../../styles/asset-finder.scss';

const DEFAULT_PAGE = 1;
/** THE NUMBER ITEMS THAT IS ALLOWED PER PAGE. */
const DEFAULT_PAGE_LIMIT = 30;
const DEFAULT_SCROLL_OFFSET = 0;

export interface SelectorUnsplashState {
    /** Search query. */
    query: string | boolean;
    /** The current page. */
    page: number;
    /** The results from the Unsplash API. */
    results: SourceData[];
    /** Scroll offset value. */
    scrollOffset: number;
}

interface Props {
    /** Callback function that will be triggered when an item is selected. */
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
    /** The number items that is allowed per page. */
    limit?: number;
    /** Callback function that will be triggered when the Unsplash Selector state changes. */
    onStateChange?: (state: SelectorUnsplashState) => void;
    /** The current state of the Unsplash Selector. */
    currentState?: SelectorUnsplashState;
}

/**
 * This is the selector for unsplash.
 */
const SelectorUnsplash: React.FC<Props> = ({ onSelect, limit = DEFAULT_PAGE_LIMIT, onStateChange, currentState }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState(currentState?.query ? currentState.query : false);
    const [page, setPage] = useState(currentState?.page ? currentState.page : DEFAULT_PAGE);
    const [scrollOffset, _setScrollOffset] = useState(currentState?.scrollOffset ? currentState.scrollOffset : DEFAULT_SCROLL_OFFSET);
    const [results, setResults] = useState(currentState?.results ? currentState.results : []);

    /**
     * Set the scroll offset based on the provided scroll value.
     * @param scroll The scroll value to set.
     */
    const setScrollOffset = (scroll: number) => {
        _setScrollOffset(scroll);
        if (onStateChange) onStateChange({ query, page, results, scrollOffset });
    };

    /**
     * First removes current results and current page. Then will set the new query and trigger the async request
     * @param {string} searchQuery The string to search for when user presses search.
     */
    const handleSearch = async (searchQuery: string) => {
        setPage(DEFAULT_PAGE); // Reset current page to 1
        setQuery(searchQuery); // Set the new searchQuery
        await handleUnsplashImages(searchQuery, true); // Handle data request. Query, limit and page are auto filled from state.
    };

    /** Load new data at the end of the page */
    const handleOnScrollEnd = async () => {
        setPage(page + 1); // Update page and request new data.
        await handleUnsplashImages();
    };

    /** Resets the current state of the Unsplash selector.*/
    const resetCurrentState = () => {
        if (onStateChange) {
            onStateChange({
                query: '',
                page: DEFAULT_PAGE,
                results: [],
                scrollOffset: DEFAULT_SCROLL_OFFSET
            });

            setResults([]);
        }
    };

    /**
     * Gets new results based on the fetched data and whether to refresh the list or not.
     * @param data - The fetched Unsplash data
     * @param refreshList - A boolean to determine whether to refresh the list or not.
     * @returns - Returns the new results.
     */
    const getNewResults = (data: UnSplashData[], refreshList: boolean) => {
        return refreshList ? UnsplashHelper.mapResults(data) : [...results, ...UnsplashHelper.mapResults(data)];
    };

    /**
     * Handle data request. Query, limit and page are auto filled from state
     * @param searchQuery - The query string or boolean to search for images. If not provided, the state is reset.
     * @param refreshList - A boolean to determine whether to refresh the list or not. Default is false.
     */
    const handleUnsplashImages = async (searchQuery?: string | boolean, refreshList = false) => {
        // If no searchQuery is provided, reset the state and return
        if (!searchQuery) {
            resetCurrentState();
            return;
        }

        // If the system is already loading, return false to prevent multiple requests
        if (isLoading) {
            return;
        }

        setIsLoading(true); // Set loading state, prevent double requests when triggered by scroll event. Will be ignored by a search request

        const data = await UnsplashService.fetchImageFromUnsplash(searchQuery, limit, page); // Fetches images from Unsplash based on the provided parameters.
        const newResults = getNewResults(data, refreshList);

        setResults(newResults); // Set new results in state
        saveStateInParentComponent(newResults, searchQuery); // Save current state in parent component

        setIsLoading(false); // Remove loading flag
    };

    /**
     * Saves the current state in the parent component
     * @param newResults - The new results to save
     * @param searchQuery - The query string or boolean used to fetch the new results
     */
    const saveStateInParentComponent = (newResults: SourceData[], searchQuery: string | boolean) => {
        if (onStateChange) {
            onStateChange({
                query: searchQuery,
                page,
                results: newResults,
                scrollOffset
            });
        }
    };

    return (
        <div className="asset-finder">
            <div className="asset-finder__toolbar">
                <SelectorToolBar
                    placeholder={Translation.get('selectors.unsplash.searchPlaceholder', 'asset-gallery-dialog')}
                    initQuery={query ? query.toString() : ''}
                    onSearch={handleSearch}
                />
            </div>
            {query && results.length === 0 && !isLoading && (
                <EmptyState
                    className="asset-finder__emptyState"
                    primaryText={Translation.get('feedback.emptyState.noResults', 'common')}
                    illustration={<Illustration illustration="no-result" />}
                />
            )}
            {!query ? (
                <EmptyState
                    className="asset-finder__emptyState"
                    primaryText={Translation.get('selectors.unsplash.emptyStateTitle', 'asset-gallery-dialog')}
                    secondaryText={Translation.get('selectors.unsplash.emptyStateDescription', 'asset-gallery-dialog')}
                    illustration={<Illustration illustration="image" />}
                />
            ) : (
                <div className="asset-finder__list">
                    <SelectorGrid
                        hideDeleteButton={true}
                        list={results}
                        scrollValue={currentState?.scrollOffset}
                        isLoading={isLoading}
                        isRefresh={isLoading && page === DEFAULT_PAGE}
                        onSelect={onSelect}
                        onScrollEnd={handleOnScrollEnd}
                        onScrollChanged={setScrollOffset}
                        classes={{ loadingIndicator: 'asset-finder__list__loading-indicator' }}
                    />
                </div>
            )}
        </div>
    );
};

export default SelectorUnsplash;
