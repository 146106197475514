import React, { Component } from 'react';
import classNames from 'classnames';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Columns from 'components/ui-base/Columns';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import SelectorGridItem from './components/selector-grid-item';

import './styles/selector-grid.scss';

interface Classes {
    loadingIndicator?: string;
}

interface Props {
    onSelect: (item: SourceData) => void;
    onRemove?: (item: SourceData) => void;
    onScrollChanged?: (scrollValue: number) => void;
    onScrollEnd?: () => void;
    isLoading?: boolean;
    isRefresh?: boolean;
    list?: SourceData[];
    scrollValue?: number;
    hideDeleteButton?: boolean;
    classes?: Classes;
}

/**
 * This grid for selecting assets
 */
class SelectorGrid extends Component<Props> {
    myRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.myRef = React.createRef();
    }

    /**
     * Setup scrolling after mounting
     */
    componentDidMount() {
        if (this.myRef.current?.scrollTop && this.props.scrollValue) {
            this.myRef.current.scrollTop = this.props.scrollValue;
        }

        this.myRef.current?.scroll({
            top: this.props.scrollValue,
            left: 100,
            behavior: 'smooth'
        });
    }

    /**
     * Handle scrolls.
     * This loads new data at the end if possible.
     * @param {Object} event Scroll event from .asset-gallery-selector
     */
    handleScroll = (event) => {
        if (this.props.onScrollChanged) {
            this.props.onScrollChanged(event.target.scrollTop);
        }
        const isAtBottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

        if (isAtBottom && this.props.onScrollEnd) {
            this.props.onScrollEnd();
        }
    };

    render() {
        const { onSelect, isLoading, isRefresh, onRemove, hideDeleteButton, classes } = this.props;

        return (
            <div className="selector-grid" ref={this.myRef} onScroll={(event) => this.handleScroll(event)}>
                <div className="selector-grid__list">
                    {!isRefresh && this.props.list && this.props.list.length > 0 && (
                        <Columns count={4}>
                            {this.props.list.map((item) => (
                                <SelectorGridItem hideDeleteButton={hideDeleteButton} onSelect={onSelect} onRemove={onRemove} key={item.url} item={item} />
                            ))}
                        </Columns>
                    )}
                    {isLoading && (
                        <div className={classNames('selector-grid__loader', classes?.loadingIndicator)}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default SelectorGrid;
