import React from 'react';
import SelectorUnsplash, {
    SelectorUnsplashState
} from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/unsplash/components/selector-unsplash';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetGalleryDialogState, { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import ComponentStore from 'components/data/ComponentStore';

interface SelectorUnsplashWrapperState {
    store: AssetGalleryDialogState['store'];
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorUnsplashWrapper component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onSelect Event handler for selecting an asset.
 */
const SelectorUnsplashWrapper: React.FC<Props> = ({ onSelect }) => {
    const { store } = useComponentStore<SelectorUnsplashWrapperState>('AssetGalleryDialog', { fields: { store: 'store' } });

    /**
     * Updates the unsplash store state.
     * @param key Key of the store.
     * @param data Data to be updated.
     */
    const handleStateChange = (key: string, data: SelectorUnsplashState) => {
        ComponentStore.setModel('AssetGalleryDialog', `store.${key}`, data);
    };

    return <SelectorUnsplash currentState={store?.unsplash} onSelect={onSelect} onStateChange={(state) => handleStateChange('unsplash', state)} />;
};

export default SelectorUnsplashWrapper;
