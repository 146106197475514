import Request from 'components/data/Request';
import UnSplashData from '../interfaces/UnsplashData';

const HOST = process.env.APP_API_HOST;

/**
 * Service for fetching images from Unsplash.
 */
class UnsplashService {
    /**
     * Fetches images from Unsplash based on the provided parameters.
     * @param searchQuery - The search query for the images.
     * @param itemsLimitPerPage - The number of items to fetch per page.
     * @param page - The page number to fetch.
     * @returns A promise that resolves to an array of UnSplashData objects.
     */
    static fetchImageFromUnsplash = async (searchQuery: string | boolean, itemsLimitPerPage: number, page: number): Promise<UnSplashData[]> => {
        try {
            // Fetch image from unsplash based on the provided parameters.
            const { data = [] } = await Request.post<UnSplashData[]>(
                `${HOST}external/unsplash?query=${searchQuery}&per_page=${itemsLimitPerPage}&page=${page}`,
                {}
            );

            return data;
        } catch (error) {
            console.error(error);
            return []; // Return empty array if something goes wrong.
        }
    };
}

export default UnsplashService;
