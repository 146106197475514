import React, { useEffect, useRef, useState } from 'react';
import { create } from '@frontify/frontify-finder';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Setup from 'components/data/Setup';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AssetHelper from 'helpers/asset.helper';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import FrontifyAsset from './data/FrontifyAsset';

import './styles/main.scss';

/** This is needed because we don't know exactly when the fontify finder is showing its content, sometimes is loaded yet it shows a blank screen before its content. */
const LOADER_TIMEOUT = 5000;
const ALLOW_MULTI_SELECT = false;

interface FrontifyConfig {
    clientId?: string;
    domain?: string;
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean, isMultipleUpload?: boolean) => void;
}

/**
 * This selector component is responsible to open the Frontify Finder and handle the selection of assets.
 */
const SelectorFrontify: React.FC<Props> = ({ onSelect }) => {
    const frontifyDivRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleOnAssetSelect = (assets: FrontifyAsset[]) => {
        const frontifyAsset = assets.length > 0 ? assets[0] : undefined;

        if (!frontifyAsset) return;
        const fileType = AssetHelper.getFileType(frontifyAsset.extension);

        const sourceData: SourceData = {
            url: frontifyAsset.downloadUrl,
            fileName: frontifyAsset.filename,
            size: frontifyAsset.size,
            height: frontifyAsset.height,
            width: frontifyAsset.width,
            extension: frontifyAsset.extension,
            fileType: fileType ?? ''
        };

        onSelect(sourceData);
    };

    /** This function is responsible to get the frontify finder instance. */
    const getFrontifyFinderInstance = async () => {
        const frontifyConfig: FrontifyConfig | undefined = Setup.getValueFromModel('frontify');

        if (!frontifyConfig?.clientId) return;

        return await create({
            clientId: frontifyConfig.clientId,
            domain: frontifyConfig.domain,
            options: { allowMultiSelect: ALLOW_MULTI_SELECT }
        });
    };

    const setupFrontifyListeners = (finder: any) => {
        finder.onAssetsChosen(handleOnAssetSelect);

        // Mounts the finder into the DOM and launches it
        finder.mount(frontifyDivRef.current);
    };

    const handleFrontifyInstance = async () => {
        const finder = await getFrontifyFinderInstance();

        if (!finder) {
            SnackbarUtils.error(Translation.get('selectors.frontify.errorFrontifyConfiguration', 'asset-gallery-dialog'));
            return;
        }

        setupFrontifyListeners(finder);

        //  This is needed because we don't know exactly when the frontify finder is showing its content, sometimes is loaded yet it shows a blank screen before its content.
        setTimeout(() => setIsLoading(false), LOADER_TIMEOUT);
    };

    useEffect(() => {
        handleFrontifyInstance();
    }, []);

    return (
        <div className="frontify-selector">
            <div>{isLoading ? <CircularProgress /> : null}</div>
            <div ref={frontifyDivRef} className="frontify-selector__content" />
        </div>
    );
};

export default SelectorFrontify;
