import React, { useState, useEffect } from 'react';
import { SelectChangeEvent, InputLabel, FormControl } from '@mui/material';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import Translation from 'components/data/Translation';

export interface VoiceSettings {
    stability: number;
    similarity_boost: number;
    style: number;
    use_speaker_boost: boolean;
}

/**
 * Props for the SelectorVoiceSettings component.
 * @property {Function} onStyleSelect - Callback function to pass the selected voice settings.
 */
interface SelectorVoiceSettingsProps {
    onStyleSelect: (voiceSettings: VoiceSettings) => void;
}

/**
 * A component to select and apply voice style and settings.
 * @component
 * @param {SelectorVoiceSettingsProps} props - The props for the component.
 */
const SelectorVoiceSettings: React.FC<SelectorVoiceSettingsProps> = ({ onStyleSelect }) => {
    const [selectedStyle, setselectedStyle] = useState<string>('Normal');

    // Effect to handle initial stlye selection
    useEffect(() => {
        const voiceSettings = mapStyleToVoiceSettings(selectedStyle);
        if (voiceSettings) {
            onStyleSelect && onStyleSelect(voiceSettings);
        }
    }, []);

    /**
     * Handles changes to the voice style selection.
     * @param {SelectChangeEvent} event - The change event from the Select component.
     */
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const selectedStyle = event.target.value as string;
        setselectedStyle(selectedStyle);
        const voiceSettings = mapStyleToVoiceSettings(selectedStyle);
        if (voiceSettings) {
            onStyleSelect && onStyleSelect(voiceSettings);
        }
    };

    /**
     * Maps a voice style to specific voice settings.
     * @param {string} style - The selected voice style.
     * @returns The voice settings for the selected style.
     */
    const mapStyleToVoiceSettings = (style: string) => {
        let settings;
        switch (style) {
            case 'Expressive':
                settings = {
                    stability: 0.12,
                    similarity_boost: 0.75,
                    style: 1,
                    use_speaker_boost: true
                };
                break;

            case 'Calm':
                settings = {
                    stability: 0.64,
                    similarity_boost: 0.44,
                    style: 0.05,
                    use_speaker_boost: false
                };
                break;
            default:
                settings = {
                    stability: 1,
                    similarity_boost: 0.8,
                    style: 0.5,
                    use_speaker_boost: false
                };
                break;
        }
        return settings;
    };

    return (
        <FormControl fullWidth className="voice-popup">
            <InputLabel className="voice-settings__label">
                {Translation.get('assetGallerySelector.selectorVoiceSettings.voiceStyle', 'content-space')}
            </InputLabel>
            <Select
                className="voice-settings__select"
                labelId="demo-simple-select-label"
                value={selectedStyle}
                label={Translation.get('assetGallerySelector.selectorVoiceSettings.voiceStyle', 'content-space')}
                onChange={handleChange}>
                <MenuItem value="Expressive">{Translation.get('assetGallerySelector.selectorVoiceSettings.expressive', 'content-space')}</MenuItem>
                <MenuItem value="Normal">{Translation.get('assetGallerySelector.selectorVoiceSettings.normal', 'content-space')}</MenuItem>
                <MenuItem value="Calm">{Translation.get('assetGallerySelector.selectorVoiceSettings.calm', 'content-space')}</MenuItem>
            </Select>
        </FormControl>
    );
};

export default SelectorVoiceSettings;
