import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import SelectorAprimo from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/aprimo/components/selector-aprimo';
import { AprimoOptions, SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AssetHelper from 'helpers/asset.helper';
import SelectorHelper from '../../../helpers/selector-helper';
import useUploadSelector from '../upload/hooks/use-upload-selector';

interface SelectorAprimoWrapperState {
    sourceData: SourceData[] | boolean;
    fileType: string | string[];
    aprimoTenant: string;
    aprimoOutputFormat: string;
    aprimoOptions?: AprimoOptions;
    extension: string;
}

interface Props {
    onMutation: (data: unknown, type?: string) => void;
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorAprimoWrapper component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onSelect On item select event.
 * @param onAdd On item add event.
 */
const SelectorAprimoWrapper: React.FC<Props> = ({ onSelect, onMutation }) => {
    const { sourceData, aprimoTenant, aprimoOptions, aprimoOutputFormat, extension } = useComponentStore<SelectorAprimoWrapperState>('AssetGalleryDialog', {
        fields: {
            sourceData: 'data.sourceData',
            aprimoTenant: 'data.aprimoData.aprimoTenant',
            aprimoOutputFormat: 'data.aprimoData.aprimoOutputFormat',
            aprimoOptions: 'data.aprimoData.aprimoOptions',
            extension: 'value.extension'
        }
    });

    const fileType = AssetHelper.getFileType(extension);

    const { handleSelectorUpload, handleSelectorRemove } = useUploadSelector(onSelect, onMutation);

    return (
        <SelectorAprimo
            list={SelectorHelper.getDataSourceListByAcceptedFileType(sourceData, fileType)}
            tenant={aprimoTenant}
            outputFormat={aprimoOutputFormat}
            options={aprimoOptions}
            onRemove={(item) => handleSelectorRemove(item, onMutation)}
            onAdd={handleSelectorUpload}
            onSelect={onSelect}
        />
    );
};

export default SelectorAprimoWrapper;
