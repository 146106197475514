import React, { Component } from 'react';
import { OutlinedInput } from '@mui/material';
import { InputAdornment } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import Divider from 'components/ui-components-v2/Divider';
import TextField from 'components/ui-components-v2/TextField';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AssetHelper from 'helpers/asset.helper';
import SelectorGrid from '../../grid';

import './../styles/selector-url.scss';

interface Props {
    acceptedTypes: string | string[] | undefined;
    onStateChange: (state: SourceData[]) => void;
    currentState: SourceData[];
    onSelect: (item: SourceData) => void;
}
interface State {
    url: string;
    list: SourceData[];
    urlError: boolean;
    helperText: string;
}

/**
 * This is the selector for adding assets by URL.
 * @param acceptedTypes The accepted types of the assets.
 * @param onStateChange Event handler for updating the state.
 * @param currentState The current state of the selector.
 * @param onSelect Event handler for selecting an asset.
 */
export class SelectorUrl extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            url: '',
            list: this.props.currentState ? this.props.currentState : [],
            urlError: false,
            helperText: ''
        };
    }

    //Add an asset to a campaign by URL
    getAssetByUrl = () => {
        const { url, list } = this.state;

        //check the type by the extension of the URL
        const extension = url.substring(url.lastIndexOf('.') + 1);
        const fileType = AssetHelper.getFileType(extension);

        //if the type is not equal too a accepted type deny the asset.
        if (!fileType || !extension || (!this.props.acceptedTypes?.includes(extension) && !this.props.acceptedTypes?.includes(fileType))) {
            this.setState({
                urlError: true,
                helperText: `Asset type not accepted, type should be ${this.props.acceptedTypes}`
            });
            return;
        }

        //retrieve the asset name and extension form the url
        const title = url.substring(url.lastIndexOf('/') + 1);

        //Set data in the right format
        const data = {
            extension,
            fileType,
            size: undefined,
            humanSize: undefined,
            title: title,
            stillUrl: url,
            url: url,
            width: undefined,
            height: undefined,
            sourceWasUrl: true
        };

        //push the new data to the list with data used by the selector
        const newList = [...list];
        newList.push(data);

        //set the state to the new list
        this.setState({ list: newList, urlError: false }, () => {
            this.props.onStateChange(this.state.list);
        });
    };

    //Remove selected asset from the list
    onRemove = (asset: SourceData) => {
        const { list } = this.state;
        const newList = list.filter((item) => item !== asset);
        this.setState({ list: newList }, () => {
            this.props.onStateChange(this.state.list);
        });
    };

    handlePressEnterToAdd = (event) => {
        if (event.key === 'Enter') {
            this.getAssetByUrl();
        }
    };

    render() {
        const { url, list, urlError, helperText } = this.state;
        return (
            <div>
                <div className="selector-url">
                    <div className="selector-url__header">{Translation.get('assetGallerySelector.selectorURL.addFileByURL', 'content-space')}</div>
                    <Divider />
                    {urlError ? (
                        <div className="selector-url__container">
                            <TextField
                                error
                                id="outlined-adornment-amount"
                                helperText={helperText}
                                className="selector-url__container__input"
                                placeholder="https://"
                                variant="outlined"
                                onKeyPress={(event) => this.handlePressEnterToAdd(event)}
                                value={url}
                                onChange={(event) => this.setState({ url: event.target.value })}
                            />
                            <Button onClick={this.getAssetByUrl} className="selector-url__container__add" variant="contained" color="primary">
                                {Translation.get('assetGallerySelector.selectorURL.addAsset', 'content-space')}
                            </Button>
                        </div>
                    ) : (
                        <div className="selector-url__container">
                            <OutlinedInput
                                size="small"
                                id="outlined-adornment-amount"
                                className="selector-url__container__input"
                                placeholder="https://"
                                onKeyPress={(event) => this.handlePressEnterToAdd(event)}
                                value={url}
                                onChange={(event) => this.setState({ url: event.target.value })}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LinkIcon className="selector-url__container__input__start-icon" />
                                    </InputAdornment>
                                }
                            />
                            <Button onClick={this.getAssetByUrl} className="selector-url__container__add" variant="contained" color="primary">
                                {Translation.get('assetGallerySelector.selectorURL.addAsset', 'content-space')}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="asset-finder__list">
                    <SelectorGrid hideDeleteButton={false} list={list} onSelect={this.props.onSelect} onRemove={(asset) => this.onRemove(asset)} />
                </div>
            </div>
        );
    }
}

export default SelectorUrl;
