import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Translation from 'components/data/Translation';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import SelectorToolbar from '../../toolbar';
import SelectorGridItem from '../../grid/components/selector-grid-item';
import AiContentGeneratorService from '../services/ai-content-generator-service';
import AiContentData from '../interfaces/ai-content-data';
import AiContentExample from './ai-content-example';
import aiContentExamples from '../data/ai-content-examples';

import '../styles/selector-ai-content.scss';

const DEFAULT_IMAGE_WIDTH = 1024;
const DEFAULT_IMAGE_HEIGHT = 1024;
const DEFAULT_FILE_TYPE = 'image';
/** The minimum length of the input prompt. */
const DEFAULT_MIN_PROMPT_LENGTH = 10;

interface Data {
    /** Prompt/Seed text */
    seedText: string;
}

/** State of the component. */
interface State {
    view: 'selection' | 'generator' | 'loading';
    response?: AiContentData;
    loading?: boolean;
    data?: Data;
    requestId?: string;
}

interface Props {
    item: SourceData;
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/** Selector component for generating AI images. */
class SelectorAiContent extends Component<Props, State> {
    variantCount = 1;

    constructor(props: Props) {
        super(props);

        this.state = {
            view: this.props?.item?.urls ? 'selection' : 'generator',
            response: { urls: this.props?.item?.urls ?? [] },
            data: { seedText: this.props.item?.text ?? '' }
        };
    }

    /**
     * Generate AI images based on the provided seed text.
     * @param seedText The seed text to generate images from.
     */
    async handleImageGeneration(seedText: string) {
        const id = uuidv4(); // Generate a unique id for the request.

        // Make sure empty seedText doesn't get posted.
        if (!seedText) {
            this.setState({ view: 'generator', data: { seedText: '' }, requestId: id, response: { urls: [] } });
            return;
        }

        this.setState({ loading: true, view: 'loading', data: { seedText }, requestId: id });

        const generateImages = await AiContentGeneratorService.generateImages(seedText); // Call the Ai content generator service to generate images.

        // If the requestId has changed, it means that the user has made a new request and the previous one should be ignored.
        if (this.state.requestId !== id) {
            return;
        }

        this.setState({ view: 'selection', response: generateImages, loading: false });
    }

    /**
     * Handles the click event when clicking on an image.
     * @param url The url of image that selected.
     * @param urls The list of urls of the images.
     */
    handleClickItem(url: string, urls: string[], seedText?: string) {
        const { onSelect } = this.props;
        const newItem: SourceData = {
            fileType: DEFAULT_FILE_TYPE,
            url,
            urls,
            text: seedText
        };

        //Passes the data to parent component that handles the image being selected
        onSelect(newItem);
    }

    /** Get the title for the image by concatenating the variant count to the title. */
    getTitle() {
        const subTitle = Translation.get('selectors.aiContent.variant', 'asset-gallery-dialog') + ` ${this.variantCount}`; // Concat the variant count to the subtitle.
        this.variantCount = this.variantCount + 1; // Increment the variant count.

        return subTitle;
    }

    /**
     * Get the subtitle for the image by concatenating the width and height.
     * @param width Width of the image.
     * @param height Height of the image.
     */
    getSubTitle(width: number, height: number) {
        return `${width} x ${height}`;
    }

    render() {
        const { view, response, loading, data } = this.state;

        return (
            <div className="selector-ai-content">
                <SelectorToolbar
                    placeholder={Translation.get('assetGalleryDialog.assetSelectView.descriptionAI', 'content-space')}
                    onSearch={(seedText) => this.handleImageGeneration(seedText)}
                    minLength={DEFAULT_MIN_PROMPT_LENGTH}
                    searchOnEnter
                    initQuery={data?.seedText ?? ''}
                />
                {view === 'generator' && (
                    <div className="selector-ai-content__preview">
                        <div className="selector-ai-content__preview__headline">
                            {Translation.get('assetGallerySelector.selectorAiContent.writePrompt', 'content-space')}
                        </div>
                        <div className="selector-ai-content__preview__subline">
                            {Translation.get('assetGallerySelector.selectorAiContent.examples', 'content-space')}
                        </div>
                        <div className="selector-ai-content__preview__images">
                            <div className="selector-ai-content__preview__images__row">
                                {aiContentExamples.map(({ description, imageUrl }, index) => (
                                    <AiContentExample
                                        key={index}
                                        classes={{ description: 'selector-ai-content__preview__description' }}
                                        description={description}
                                        imageUrl={imageUrl}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {view === 'loading' && (
                    <div className="selector-ai-content__loader">
                        <CircularProgress />
                    </div>
                )}
                {view === 'selection' && !loading && (
                    <div className="selector-ai-content__selection">
                        <div className="selector-ai-content__selection__images">
                            {response?.urls.map((url) => (
                                <SelectorGridItem
                                    onSelect={() => this.handleClickItem(url, response.urls, data?.seedText)}
                                    hideDeleteButton
                                    key={url}
                                    item={{
                                        url: url,
                                        width: DEFAULT_IMAGE_WIDTH,
                                        height: DEFAULT_IMAGE_HEIGHT,
                                        fileType: DEFAULT_FILE_TYPE,
                                        subtitle: this.getSubTitle(DEFAULT_IMAGE_WIDTH, DEFAULT_IMAGE_HEIGHT),
                                        title: this.getTitle()
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SelectorAiContent;
