import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import SelectorAiContent from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/ai-content/components/selector-ai-content';
import AssetGalleryDialogState, { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';

interface SelectorAiContentWrapperState {
    value: AssetGalleryDialogState['value'];
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorAiContent component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onSelect Event handler for selecting an asset.
 */
const SelectorAiContentWrapper: React.FC<Props> = ({ onSelect }) => {
    const { value } = useComponentStore<SelectorAiContentWrapperState>('AssetGalleryDialog', { fields: { value: 'value' } });

    return <SelectorAiContent item={value} onSelect={onSelect} />;
};

export default SelectorAiContentWrapper;
