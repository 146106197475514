/* eslint-disable react/display-name */
import React from 'react';
import { SelectorGridItemSourceData } from './selector-grid-item';

interface Props {
    item: SelectorGridItemSourceData;
    src: string;
}

/**
 * Renders a grid item for a video asset in the selector grid.
 */
const SelectorGridItemVideo = React.forwardRef<HTMLVideoElement, Props>(({ item, src }, ref) => {
    return (
        <video key={item.url} poster={item.thumbnail ? item.thumbnail : undefined} width={'100%'} src={src} ref={ref} preload="none" disableRemotePlayback />
    );
});

export default SelectorGridItemVideo;
