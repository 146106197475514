import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import SelectorReuseImage from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/reuse-image/components/selector-reuse-image';
import AssetGalleryDialogState, { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';

interface SelectorReuseImageWrapperState {
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    maxOutputHeight: AssetGalleryDialogState['data']['assetData']['maxOutputHeight'];
    maxOutputWidth: AssetGalleryDialogState['data']['assetData']['maxOutputWidth'];
    cropMode?: string;
    ratios: string[];
    canSkipCropper: AssetGalleryDialogState['conditionProps']['canSkipCropper'];
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorReuseImageWrapper component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onSelect Event handler for selecting an asset.
 */
const SelectorReuseImageWrapper: React.FC<Props> = ({ onSelect }) => {
    const { outputHeight, outputWidth, maxOutputHeight, maxOutputWidth, cropMode, ratios, canSkipCropper } = useComponentStore<SelectorReuseImageWrapperState>(
        'AssetGalleryDialog',
        {
            fields: {
                outputHeight: 'data.assetData.outputHeight',
                outputWidth: 'data.assetData.outputWidth',
                maxOutputHeight: 'data.assetData.maxOutputHeight',
                maxOutputWidth: 'data.assetData.maxOutputWidth',
                cropMode: 'config.cropper.cropMode',
                ratios: 'config.cropper.ratios',
                canSkipCropper: 'conditionProps.canSkipCropper'
            }
        }
    );

    return (
        <SelectorReuseImage
            onSelect={onSelect}
            cropSettings={{
                outputHeight: outputHeight,
                outputWidth: outputWidth,
                maxOutputHeight: maxOutputHeight,
                maxOutputWidth: maxOutputWidth,
                mode: cropMode,
                ratios: ratios,
                canSkipCropper: canSkipCropper
            }}
        />
    );
};

export default SelectorReuseImageWrapper;
