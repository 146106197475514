import React, { Component } from 'react';
import classNames from 'classnames';
import Button from 'components/ui-components-v2/Button';
import Loader from 'components/ui-components/Loader';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import LanguageAssistant from 'components/ui-components/LanguageAssistant';
import SelectorPopUp, { Voice } from './selector-popup';
import SelectorVoiceSetting, { VoiceSettings } from './selector-voice-settings';
import VoiceOverService from '../services/voice-over-service';

import '../styles/selector-voice-over-generator.scss';
import '../styles/selector-popup.scss';
import '../styles/selector-voice-over-settings.scss';

interface Props {
    item: any;
    onSelect: (item: SourceData) => void;
}

interface State {
    audioUrl: string | null;
    loading: boolean;
    voices: Voice[];
    selectedVoice: string;
    text: string;
    voiceSettings: VoiceSettings | object;
    noTextError: boolean;
    noVoiceError: boolean;
    view?: string;
}

/**
 * A component for generating voice overs with different settings and voice samples.
 */
class SelectorVoiceOverGenerator extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            audioUrl: props.item?.originalUrl || null,
            loading: false,
            voices: [],
            selectedVoice: props.item?.voiceId || '',
            text: props.item?.text || '',
            voiceSettings: {},
            noTextError: false,
            noVoiceError: false
        };
    }

    handleGeneratedText = (generatedText) => {
        this.setState({ text: generatedText });
    };

    /**
     * Handles the change event of the voice selector.
     * @param {React.ChangeEvent<HTMLSelectElement} event - The change event.
     */
    handleVoiceChange = (event) => {
        this.setState({
            selectedVoice: event.target.value
        });
    };

    /**
     * Generates the Text-to-Speech (TTS) audio using selected voice and settings.
     */
    handleGenerateTTS = async () => {
        this.setState({ loading: true, view: 'loading' });
        const { selectedVoice, voiceSettings, text } = this.state;

        try {
            if (!selectedVoice) {
                this.setState({ noVoiceError: true });
                SnackbarUtils.error(Translation.get('assetGallerySelector.selectorVoiceOverGenerator.noVoiceSelected', 'content-space'));
                return;
            }
            if (!text || text.trim() === '') {
                this.setState({ noTextError: true });
                SnackbarUtils.error(Translation.get('assetGallerySelector.selectorVoiceOverGenerator.enterText', 'content-space'));
                return;
            }

            const payload = {
                text: text,
                modelId: 'eleven_multilingual_v2',
                voiceId: selectedVoice,
                voiceSettings: {
                    language_id: 'en',
                    useSpeakerBoost: true,
                    ...voiceSettings
                },
                outputFormat: 'mp3_44100',
                optimizeStreamingLatency: 0
            };

            const result = await VoiceOverService.generateVoiceOver(payload);

            if (result) {
                const { url } = result;
                if (typeof url !== 'string') {
                    throw new Error(Translation.get('assetGallerySelector.selectorVoiceOverGenerator.undefinedUrl', 'content-space'));
                }
                this.setState({ audioUrl: url });
            }
        } catch (error: any) {
            // Log the entire error object to see its structure
            SnackbarUtils.error(error.message || Translation.get('assetGallerySelector.selectorVoiceOverGenerator.failedVoiceOverGeneration', 'content-space'));
        } finally {
            this.setState({ loading: false });
        }
    };

    /**
     * Fetches the initial set of voices when the component mounts.
     */
    getInitialData = async () => {
        try {
            const result = await VoiceOverService.getVoices();
            if (result && result.data) {
                // Accessing the data property
                this.setState({ voices: result.data });
            }
        } catch (error) {
            SnackbarUtils.error(Translation.get('assetGallerySelector.selectorVoiceOverGenerator.failedVoices', 'content-space'));
        }
    };

    /**
     * React lifecycle method: componentDidMount
     * It's called after the component has been rendered to the DOM.
     */
    componentDidMount() {
        this.getInitialData();
    }

    /**
     * Plays the audio sample.
     * @param {string} sampleUrl - The URL of the audio sample to be played.
     */
    playSample(sampleUrl) {
        const audio = new Audio(sampleUrl);
        audio.play();
    }

    /**
     * Handler for text change to reset error state.
     */
    handleChangeText = (event) => {
        this.setState({ text: event.target.value, noTextError: false });
    };

    render() {
        const { audioUrl, loading, selectedVoice, text } = this.state;
        const { onSelect } = this.props;

        return (
            <div className="selector-voice-over-generator">
                <div className="selector-voice-over-generator__header">
                    <h1>{Translation.get('assetGallerySelector.selectorVoiceOverGenerator.title', 'content-space')}</h1>
                </div>
                <div className="selector-voice-over-generator__content">
                    <div className="selector-voice-over-generator__content__select-components">
                        <SelectorPopUp
                            voices={this.state.voices}
                            selectedVoiceId={selectedVoice}
                            onVoiceSelect={(voice) => {
                                this.setState({ selectedVoice: voice.voice_id });
                            }}
                        />

                        <div className="selector-voice-over-generator__content__voice-settings">
                            <SelectorVoiceSetting
                                onStyleSelect={(voiceSettings) => {
                                    this.setState({ voiceSettings: voiceSettings });
                                }}
                            />
                        </div>
                    </div>
                    <div className="selector-voice-over-generator__content__text-box">
                        <div className="selector-voice-over-generator__label-assistant">
                            <label>{Translation.get('assetGallerySelector.selectorVoiceOverGenerator.textBoxLabel', 'content-space')}</label>

                            <LanguageAssistant language="EN" onMutation={this.handleGeneratedText} value={this.state.text} type="plain" />
                        </div>
                        <textarea
                            className={classNames('selector-voice-over-generator__content__text-box__text-area', {
                                'selector-voice-over-generator__content__text-box__text-area--error': this.state.noTextError
                            })}
                            value={text}
                            rows={8}
                            cols={100}
                            onChange={this.handleChangeText}
                        />
                    </div>
                    <div className="selector-voice-over-generator__content__button-container">
                        <Button onClick={this.handleGenerateTTS} variant="contained" disabled={loading || !selectedVoice || !text}>
                            {loading
                                ? Translation.get('assetGallerySelector.selectorVoiceOverGenerator.generatingVoiceOver', 'content-space')
                                : Translation.get('assetGallerySelector.selectorVoiceOverGenerator.generateVoiceOver', 'content-space')}
                        </Button>
                    </div>

                    {loading && (
                        <div>
                            <Loader />
                        </div>
                    )}
                    {audioUrl && !loading && (
                        <div className="selector-voice-over-generator__content__result">
                            <div className="selector-voice-over-generator__content__result__audio-container">
                                <label>{Translation.get('assetGallerySelector.selectorVoiceOverGenerator.generatedAudioTitle', 'content-space')}</label>
                                <br></br>
                                <audio
                                    className="selector-voice-over-generator__content__result__audio-container__audio-file"
                                    controls
                                    preload="auto"
                                    src={audioUrl}
                                />
                            </div>
                            <Button
                                className="selector-voice-over-generator__content__result__button"
                                variant="contained"
                                disabled={loading}
                                onClick={() => {
                                    const newItem: SourceData = {
                                        fileType: 'audio',
                                        url: audioUrl,
                                        title: 'Voice over ' + selectedVoice,
                                        voiceId: selectedVoice,
                                        text
                                    };

                                    //Passes the data to parent component that handles the audio being selected
                                    onSelect(newItem);
                                }}>
                                {Translation.get('assetGallerySelector.selectorVoiceOverGenerator.useVoice', 'content-space')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default SelectorVoiceOverGenerator;
