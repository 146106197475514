import axios from 'axios';
import * as Sentry from '@sentry/react';
import User from 'components/data/User';

type VideoLength = '14_frames_with_svd' | '25_frames_with_svd_xt';
type SizingStrategy = 'maintain_aspect_ratio' | 'crop_to_16_9' | 'use_image_dimensions';

interface ConvertImageToVideoResponse {
    url: string;
}

const CONVERT_IMAGE_TO_VIDEO_ERROR = 'Image to video conversion failed';

/** Service to convert an image to a video.*/
class ImageToVideoService {
    /**
     * Converts an image to a video by requesting to the media/imageToVideo endpoint.
     * @param imageUrl Url of the image to convert to video.
     * @param videoLength Length of the video, either 14_frames_with_svd or 25_frames_with_svd_xt.
     * @param fps Frames per second, the value can only between 5 and 30 and the default is 6.
     * @param motion Increase overall motion in the generated video. The value can only be between 1 and 255 and the default is 0.
     * @param noise Increase overall noise in the generated video. The default value is 0.02.
     * @param decoding The number of frames to decode at a time. The default value is 14.
     * @param sizingStrategy Decide how to resize the input image. The default value is maintain_aspect_ratio, other options are crop_to_16_9 and use_image_dimensions.
     * @returns The url of the generated video.
     */
    static convertImageToVideo = async (
        imageUrl: string,
        videoLength?: VideoLength,
        fps?: number,
        motion?: number,
        noise?: number,
        decoding?: number,
        sizingStrategy?: SizingStrategy
    ) => {
        try {
            const { data } = await axios.post<ConvertImageToVideoResponse>(
                process.env.APP_MEDIA_URL + 'media/imageToVideo',
                { url: imageUrl, videoLength, fps, motion, noise, decoding, sizingStrategy },
                {
                    headers: {
                        Authorization: `Bearer ${User.get('mediaServicesApiToken')}`
                    }
                }
            );

            return data.url;
        } catch (error) {
            Sentry.captureException(CONVERT_IMAGE_TO_VIDEO_ERROR + ` ${error}`);
            return null;
        }
    };
}

export default ImageToVideoService;
