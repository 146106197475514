import { v4 as uuidv4 } from 'uuid';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

/** The type of error that could occur during the image to video process.*/
export type ImageToVideoErrorType = 'upload' | 'generate';

const DEFAULT_VIDEO_EXTENSION = 'mp4';
const DEFAULT_VIDEO_FILE_TYPE = 'video';

/**
 * Helper class for the image to video selector.
 */
class ImageToVideoHelper {
    /**
     * Get the first item from the uploaded list.
     * @param listOfSourceData List of source data that has been uploaded.
     * @returns The first item from the uploaded list or null if the list is empty.
     */
    static getFirstItemFromUploadedList = (listOfSourceData: SourceData[]) => {
        // If the list of source data is empty, return
        if (listOfSourceData.length <= 0) {
            return null;
        }

        const firstItemIndex = 0;
        return listOfSourceData[firstItemIndex];
    };

    /**
     * Update the image source item to a video source item.
     * @param imageSourceData Image source data to be updated.
     * @param videoUrl The video url to be updated to the image source data.
     * @returns The updated video source data.
     */
    static updateImageItemToVideo = (imageSourceData: SourceData, videoUrl: string) => {
        const videoItem: SourceData = {
            ...imageSourceData,
            id: uuidv4(),
            fileType: DEFAULT_VIDEO_FILE_TYPE,
            type: DEFAULT_VIDEO_FILE_TYPE,
            extension: DEFAULT_VIDEO_EXTENSION,
            key: videoUrl,
            url: videoUrl
        };

        return videoItem;
    };

    /**
     * Shows the error message based on the error type.
     * @param errorType The type of error to be handled, could be either 'upload' or 'generate'.
     */
    static showErrorMessage = (errorType: ImageToVideoErrorType) => {
        if (errorType === 'upload') {
            SnackbarUtils.error(Translation.get('selectors.imageToVideo.errorUploadingImage', 'asset-gallery-dialog'));
            return;
        }

        SnackbarUtils.error(Translation.get('selectors.imageToVideo.errorGeneratingVideo', 'asset-gallery-dialog'));
    };
}

export default ImageToVideoHelper;
