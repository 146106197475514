import axios from 'axios';
import User from 'components/data/User';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

/**
 * Service class for handling voice over generation and retrieval of voices.
 */

export default class VoiceOverService {
    /**
     * Generates a voice over from the given text and settings.
     * @async
     * @param {Object} data - The data for voice over generation including text, voice settings, etc.
     * @returns {Promise<Object>} The response data from the voice over generation API.
     * @throws Will throw an error if the HTTP request fails.
     */
    static generateVoiceOver = async (data) => {
        try {
            const response = await axios.post(process.env.APP_MEDIA_URL + 'media/voiceOver', data, {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
            });
            return response.data;
        } catch (error) {
            SnackbarUtils.error('Failed to generate voice over');
        }
    };

    /**
     * Retrieves a list of available voices.
     * @async
     * @returns {Promise<Object[]>} The list of voices from the API.
     * @throws Will throw an error if the HTTP request fails.
     */
    static getVoices = async () => {
        try {
            const response = await axios.get(process.env.APP_MEDIA_URL + 'media/voiceOver/voices', {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
            });
            return response.data;
        } catch (error) {
            SnackbarUtils.error('Failed to get voices');
            throw error;
        }
    };
}
