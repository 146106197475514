import React, { Component } from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import AssetHelpers from 'components/data/AssetHelpers';
import WithContentSpaceHandler from 'components/asset-library/DataHandler/';
import InfiniteScroll from 'components/ui-components/InfiniteScroll';
import Columns from 'components/ui-base/Columns';
import Loader from 'components/ui-components/Loader';
import Translation from 'components/data/Translation';
import SelectorGridItem from '../../grid/components/selector-grid-item';

import '../styles/selector-assets-grid.scss';

/**
 * This grid for selecting assets
 */
class SelectorAssetsGrid extends Component {
    constructor() {
        super();

        this.scrollParentRef = React.createRef();
    }

    render() {
        const { items, onSelect, onSelectCrop, assetsFetched, onRemove, hideDeleteButton, fetchMoreAssets, itemsNext } = this.props;
        const hasMore = items && items.length >= 25 && itemsNext > -1;

        return (
            <div className="selector-assets-grid">
                <div className="selector-assets-grid__list" ref={this.scrollParentRef}>
                    <Loader isLoading={!assetsFetched}>
                        {items.length === 0 && (
                            <div className="selector-assets-grid__empty">{Translation.get('assetGallerySelector.noAssetsFound', 'content-space')}</div>
                        )}
                        {items && items.length > 0 && (
                            <InfiniteScroll
                                loadMore={() => fetchMoreAssets()}
                                hasMore={hasMore}
                                initialLoad={false}
                                useWindow={false}
                                getScrollParent={() => this.scrollParentRef.current}
                                loader={
                                    <div className="selector-assets-grid__loader" key={0}>
                                        <CircularProgress />
                                    </div>
                                }>
                                <Columns count={4}>
                                    {AssetHelpers.mapAssets(items).map((item) => (
                                        <SelectorGridItem
                                            hideDeleteButton={hideDeleteButton}
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                            onSelectCrop={onSelectCrop}
                                            key={item.url}
                                            item={item}
                                            dataCyPrefix="assetGalleryDialog-assetLibrarySelector"
                                        />
                                    ))}
                                </Columns>
                            </InfiniteScroll>
                        )}
                    </Loader>
                </div>
            </div>
        );
    }
}

export default WithContentSpaceHandler(SelectorAssetsGrid);
