import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import UnSplashData from '../interfaces/UnsplashData';

const DEFAULT_IMAGE_WIDTH = 1080;
const DEFAULT_IMAGE_EXTENSION = 'jpg';
const DEFAULT_ASSET_TYPE = 'image';

class UnsplashHelper {
    /**
     * Map the results.
     * This takes the original data and processes this to the final data
     * @param {Array} data The results coming from Unsplash.
     */
    static mapResults = (data: UnSplashData[]): SourceData[] => {
        //urls.regular returns the image normalized at a width of 1080 pixels.
        //result.width and result.height are the sizes of the original image which is not normalized to 1080px
        return data.map((result) => {
            const sourceData: SourceData = {
                extension: DEFAULT_IMAGE_EXTENSION,
                fileType: DEFAULT_ASSET_TYPE,
                size: undefined,
                humanSize: undefined,
                title: result.alt_description,
                stillUrl: result.urls.thumb,
                url: result.urls.regular,
                width: DEFAULT_IMAGE_WIDTH,
                height: result.height * (DEFAULT_IMAGE_WIDTH / result.width)
            };

            return sourceData;
        });
    };
}

export default UnsplashHelper;
