import React, { useState, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import CollectionView from 'components/ui-components/CollectionView';
import InfiniteScroll from 'components/ui-components/InfiniteScroll';
import Columns from 'components/ui-base/Columns';
import Translation from 'components/data/Translation';
import Loader from 'components/ui-components/Loader';
import AssetHelpers from 'components/data/AssetHelpers';

import '../styles/selector-collections-grid.scss';

/**
 * Fomat the collections so the previw tiles can be shown.
 * @param {*} collections
 * @param {*} acceptedTypes
 * @returns
 */
const getFormatedCollections = (collections, acceptedTypes) => {
    const relevantCollections = AssetHelpers.getRelevantCollections(cloneDeep(collections), acceptedTypes);
    const formattedCollections = relevantCollections.map((collection) => {
        collection.preview = AssetHelpers.getCollectionPreviewItems(collection);

        return collection;
    });
    return formattedCollections;
};

const SelectorCollectionsGrid = ({ collections, collectionsNext, acceptedTypes, collectionsFetched, setOpenCollection, fetchMoreCollections }) => {
    const [formattedCollections, setFormattedCollections] = useState(getFormatedCollections(collections, acceptedTypes));
    const scrollParentRef = useRef();

    useEffect(() => {
        setFormattedCollections(getFormatedCollections(collections, acceptedTypes));
    }, [collections]);

    useEffect(() => {
        if (formattedCollections.length < 25 && collectionsNext > -1) {
            fetchMoreCollections();
        }
    }, [collectionsNext]);

    const openCollection = (collection) => {
        if (setOpenCollection) {
            setOpenCollection(collection.referenceId);
        }
    };

    return (
        <div className="selector-collections-grid">
            <div className="selector-collections-grid__list" ref={scrollParentRef}>
                <Loader isLoading={!collectionsFetched}>
                    {formattedCollections.length === 0 && (
                        <div className="selector-collections-grid__empty">
                            {Translation.get('assetGallerySelector.selectorCollectionsGrid.noCollectionsFound', 'content-space')}
                        </div>
                    )}
                    {formattedCollections && formattedCollections.length > 0 && (
                        <InfiniteScroll
                            loadMore={fetchMoreCollections}
                            pageStart={collections.length / 25}
                            initialLoad={false}
                            hasMore={collections && collections.length > 0 && collectionsNext > -1}
                            useWindow={false}
                            getScrollParent={() => scrollParentRef.current}
                            loader={
                                <div className="selector-collections-grid__loader" key={0}>
                                    <CircularProgress />
                                </div>
                            }>
                            <Columns count={3}>
                                {collections.map((collection) => (
                                    <div className="selector-collections-grid__item" key={`collection-${collection._id}`}>
                                        <CollectionView
                                            title={collection.name}
                                            thumbnail={collection.thumbnail}
                                            items={collection.preview}
                                            count={collection.assetCount}
                                            subCollectionCount={collection.subCollectionCount}
                                            categories={collection.categories}
                                            acceptedTypes={acceptedTypes}
                                            onClick={() => openCollection(collection)}
                                        />
                                    </div>
                                ))}
                            </Columns>
                        </InfiniteScroll>
                    )}
                </Loader>
            </div>
        </div>
    );
};
export default SelectorCollectionsGrid;
