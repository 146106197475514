import React from 'react';
import classNames from 'classnames';
import SearchField from 'components/ui-components/SearchField';
import ViewSwitch from 'components/ui-components/ViewSwitch';
import SelectorViewType from '../interfaces/SelectorViewType';

import '../styles/selector-view-search-bar.scss';

interface Props {
    viewType: SelectorViewType;
    className?: string;
    onSearch: (searchQuery: string) => void;
    onViewChange: (view: SelectorViewType) => void;
}

const SelectorViewSearchBar = ({ viewType, className, onSearch, onViewChange }: Props) => {
    return (
        <div className={classNames('selector-view-search-bar', className)}>
            <SearchField onChange={(_, value) => onSearch(value)} />
            <ViewSwitch onSetDisplayType={onViewChange} displayType={viewType} />
        </div>
    );
};

export default SelectorViewSearchBar;
