import React, { useState, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import WithContentSpaceHandler from 'components/asset-library/DataHandler/';
import AssetHelpers from 'components/data/AssetHelpers';
import InfiniteScroll from 'components/ui-components/InfiniteScroll';
import CollectionView from 'components/ui-components/CollectionView';
import Columns from 'components/ui-base/Columns';
import Translation from 'components/data/Translation';
import Loader from 'components/ui-components/Loader';
import SelectorGridItem from '../../grid/components/selector-grid-item';

import '../styles/selector-collection.scss';

/**
 * Get the list of collection items
 * @param {string} collectionReferenceId
 * @param {object} collectionItems
 * @returns {array}
 */
const getList = (collectionReferenceId, collectionItems) => {
    if (!collectionReferenceId) return [];
    if (!Array.isArray(collectionItems[collectionReferenceId])) return [];
    return collectionItems[collectionReferenceId];
};

/**
 * Get the ist of subcollections
 * @param {string} collectionReferenceId
 * @param {object} collectionSubcollections
 * @returns {array}
 */
const getSubcollections = (collectionReferenceId, collectionSubcollections, acceptedTypes) => {
    if (!collectionReferenceId) return [];
    if (!Array.isArray(collectionSubcollections[collectionReferenceId])) return [];
    const collections = collectionSubcollections[collectionReferenceId];
    const relevantCollections = AssetHelpers.getRelevantCollections(cloneDeep(collections), acceptedTypes);
    const formattedCollections = relevantCollections.map((collection) => {
        collection.preview = AssetHelpers.getCollectionPreviewItems(collection);

        return collection;
    });
    return formattedCollections;
};

/**
 * Check if there are more items available on the API
 * @param {array} list
 * @param {number} next
 * @param {number} pageLength
 * @returns {boolean}
 */
const getHasMore = (list, next, pageLength = 25) => {
    if (!list) return false;
    if (next < 0) return false;
    if (list.length < pageLength) return false;
    return list.length % pageLength === 0;
};

const SelectorCollection = ({
    onSelect,
    assetsFetched,
    locationView,
    fetchMoreCollectionItems,
    collectionReferenceId,
    collectionItems,
    collectionItemsNext,
    collectionSubcollections,
    acceptedTypes,
    setOpenCollection
}) => {
    const [list, setList] = useState(getList(collectionReferenceId, collectionItems));
    const [subcollections, setSubcollections] = useState(getSubcollections(collectionReferenceId, collectionSubcollections, acceptedTypes));
    const [hasMore, setHasMore] = useState(getHasMore(list, collectionItemsNext));
    const scrollParentRef = useRef();

    useEffect(() => {
        setList(getList(collectionReferenceId, collectionItems));
    }, [collectionReferenceId, collectionItems]);

    useEffect(() => {
        setSubcollections(getSubcollections(collectionReferenceId, collectionSubcollections));
    }, [collectionReferenceId, collectionSubcollections]);

    useEffect(() => {
        setHasMore(getHasMore(list, collectionItemsNext));
    }, [list, collectionItemsNext]);

    return (
        <React.Fragment>
            <div className="selector-collection" ref={scrollParentRef}>
                {locationView === 'collectionItems' && subcollections.length > 0 && (
                    <div className="selector-collection__subcollections">
                        <Columns count={4}>
                            {subcollections.map((collection) => (
                                <div className="selector-collection__item" key={`collection-${collection._id}`}>
                                    <CollectionView
                                        title={collection.name}
                                        thumbnail={collection.thumbnail}
                                        items={collection.preview}
                                        count={collection.assetCount}
                                        subCollectionCount={collection.subCollectionCount}
                                        categories={collection.categories}
                                        acceptedTypes={acceptedTypes}
                                        small
                                        onClick={() => setOpenCollection(collection.referenceId)}
                                    />
                                </div>
                            ))}
                        </Columns>
                    </div>
                )}
                <Loader isLoading={!assetsFetched}>
                    {list && list.length > 0 && (
                        <InfiniteScroll
                            loadMore={() => fetchMoreCollectionItems(collectionReferenceId)}
                            hasMore={hasMore}
                            initialLoad={false}
                            getScrollParent={() => scrollParentRef.current}
                            loader={
                                <React.Fragment key={0}>
                                    <div className="selector-collection__loader">
                                        <CircularProgress />
                                    </div>
                                </React.Fragment>
                            }
                            useWindow={false}>
                            <Columns count={4}>
                                {AssetHelpers.mapAssets(list).map((item) => (
                                    <SelectorGridItem hideDeleteButton={true} onSelect={onSelect} key={item.url} item={item} />
                                ))}
                            </Columns>
                        </InfiniteScroll>
                    )}
                    {list.length === 0 && (
                        <div className="selector-collection__empty"> {Translation.get('assetGallerySelector.noAssetsFound', 'content-space')}</div>
                    )}
                </Loader>
            </div>
        </React.Fragment>
    );
};

export default WithContentSpaceHandler(SelectorCollection, { type: 'overview' });
