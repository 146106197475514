import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetGalleryDialogState, { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import SelectorVoiceOverGenerator from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/voice-over/components/selector-voice-over-generator';

interface SelectorVoiceOverWrapperState {
    value: AssetGalleryDialogState['value'];
}

interface Props {
    onSelect: (item: SourceData, skipCropper?: boolean) => void;
}

/**
 * The responsibility of this wrapper component is to that renders the SelectorVoiceOverGenerator component and inject necessary props to it which comes from the AssetGalleryDialog Component store.
 * @param onSelect Event handler for selecting an asset.
 */
const SelectorVoiceOverWrapper: React.FC<Props> = ({ onSelect }) => {
    const { value } = useComponentStore<SelectorVoiceOverWrapperState>('AssetGalleryDialog', { fields: { value: 'value' } });

    return <SelectorVoiceOverGenerator item={value} onSelect={onSelect} />;
};

export default SelectorVoiceOverWrapper;
