import AssetGalleryDialogSelectorType from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogSelectorType';
import AssetGalleryDialogState, { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AssetGalleryDialogSidebarSelector from '../interfaces/AssetGalleryDialogSidebarSelector';

/**
 * Helper class for handling the selectors functionalities.
 */
class SelectorHelper {
    /**
     * Filters the provided selectors based on the provided selector types.
     * @param selectorTypes All the selector types that should be included in the filtered list.
     * @param selectors All the selectors that should be filtered.
     * @returns The filtered selectors.
     */
    static filterSidebarSelectorsByTypes = (
        selectorTypes: AssetGalleryDialogSelectorType[],
        selectors: AssetGalleryDialogSidebarSelector[]
    ): AssetGalleryDialogSidebarSelector[] => {
        return selectors.filter((selector) => selectorTypes.includes(selector.type));
    };

    /**
     * Filter sourceData by accepted fileType(s)
     * @param sourceData - The source data to filter.
     * @param fileType - The accepted file type(s) to filter by.
     * @returns The filtered data.
     */
    static getDataSourceListByAcceptedFileType = (sourceData: AssetGalleryDialogState['data']['sourceData'], fileType?: string | string[]): SourceData[] => {
        return !Array.isArray(sourceData) || !sourceData
            ? []
            : sourceData
                  .filter((item: any) => {
                      if (item && fileType && fileType.indexOf(item.fileType) > -1) return item;
                      return false;
                  })
                  .reverse();
    };
}

export default SelectorHelper;
